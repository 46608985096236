const headerHtml = document.querySelector("html");

export default function HeaderMenuClick() {
  const header = document.querySelector(".js-header");
  const headerNavMenu = document.querySelector(".js-menu");

  headerNavMenu.addEventListener("click", () => {
    if (headerNavMenu.classList.contains("is-close") === true) {
      header.classList.add("is-open");
      header.classList.remove("is-close");
      headerNavMenu.classList.add("is-open");
      headerNavMenu.classList.remove("is-close");
      headerHtml.classList.add("is-fixed");
    } else {
      header.classList.add("is-close");
      header.classList.remove("is-open");
      headerNavMenu.classList.add("is-close");
      headerNavMenu.classList.remove("is-open");
      headerHtml.classList.remove("is-fixed");
    }
  });

  const headerNavMenuItems = document.querySelectorAll(".js-menuItem");

  headerNavMenuItems.forEach(headerNavMenuItem => {
    headerNavMenuItem.addEventListener("click", () => {
      if (headerNavMenu.classList.contains("is-open") === true) { {
        header.classList.add("is-close");
        header.classList.remove("is-open");
        headerNavMenu.classList.add("is-close");
        headerNavMenu.classList.remove("is-open");
        headerHtml.classList.remove("is-fixed");
      }};
    });
  });

}
