import simpleParallax from 'simple-parallax-js';

export default function parallax() {
  let image = document.getElementsByClassName('p-about__image');
  new simpleParallax(image, {
    overflow: true,
    delay: 0,
  });

  let text = document.getElementsByClassName('p-about__view__text');
  new simpleParallax(text, {
    overflow: true,
    delay: 0,
  });
}