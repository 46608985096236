const txtElemAry = document.querySelectorAll('.js-title') // 要素の取得

export function txtSplit() {
  txtElemAry.forEach( item => {
    let txt = '' // HTMLの導入部分初期化
    const textContentAry = item.textContent.split('') // テキスト1文字ずつを配列に格納
    textContentAry.forEach( item2 => {
      txt += item2.replace(/(\S)/g, '<span>$&</span>') // 追加するHTMLなど記載
    })
    item.innerHTML = txt // HTMLの内容を変更
  })
}

export function addCss() {
  const defaultTime = 0 // 初期の秒数z
  txtElemAry.forEach( item => {
      let animeTime = 0.03; // 要素ごとに変化させる秒数
      let aryNumner = 0; // 配列の番号(初期値0)
      [...item.children].forEach( item2 => {
        aryNumner++
        item2.style.animationDelay = `${defaultTime + aryNumner * animeTime}s`
      }
    ) // cssのアニメーションの実行時間の設定
  })
}
