import gsap, { Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Power4);


export default function scrollEffect() {
  const items = gsap.utils.toArray(".js-fadein");
  
  items.forEach((item) => {
    gsap.fromTo(
      item,
      {
        y: 5,
        autoAlpha: 0,
      },
      {
        y: 0,
        autoAlpha: 1,
        delay: 0.4,
        duration: 1.5,
        ease: "power4.out",
        scrollTrigger: {
          trigger: item,
          start: "top 85%",
        },
      }
    );
  });

  const titles = gsap.utils.toArray(".js-title");
  
  titles.forEach((title) => {
    ScrollTrigger.create({
      trigger: title,
      start: 'top 95%',
      toggleClass: {targets: title, className: "js-active"},
      once: true,
    });
  });
}