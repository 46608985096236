import "../sass/style.scss";
import viewHiehgt from "./viewHeight"
import HeaderMenuClick from "./header"
import headerHideScroll from "./headerHide"
// import loadFn from "./load"
import slider from "./slider"
import note from "./note"
import parallax from "./parallax"
import scrollEffect from "./scrollEffect"
import calculate from "./calculator"
import {txtSplit, addCss} from './title.js';
import smoothScroll from './smoothScroll.js';

viewHiehgt();
HeaderMenuClick();
// loadFn();
slider();
note();
parallax();
scrollEffect();
txtSplit();
addCss();
headerHideScroll();
smoothScroll();

if(document.getElementById('total')) {
  let button = document.getElementById('total');
  
  button.addEventListener('click', () => {
    calculate();
  });
}
