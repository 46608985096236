export default function smoothScroll() {
  const a = document.querySelectorAll('a[href^="#"]');
  for (let i = 0; i < a.length; i++) {
    a[i].addEventListener("click", (e) => {
      e.preventDefault();
      let href = a[i].getAttribute("href");
      let target = document.getElementById(href.replace("#", ""));
      const rect = target.getBoundingClientRect();
      const set = window.pageYOffset || document.documentElement.scrollTop;
      const pos = rect.top + set;
      window.scrollTo({
        top: pos,
        behavior: "smooth",
      });
    });
  }
}