export default function headerHideScroll() {
  let pos = 0;
  const header = document.getElementById("header");
  const mvHeightElem = document.getElementsByClassName("js-head");

  const mvHeight = mvHeightElem[0].clientHeight;

  const onScroll = () => {
    if (pos > mvHeight) {
      header.classList.add("is-hide");
    } else if (pos < mvHeight) {
      header.classList.remove("is-hide");
    }
  };

  window.addEventListener("scroll", () => {
    pos = window.scrollY;
    onScroll();
  });
}