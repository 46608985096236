import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade ]);

// import styles bundle
import 'swiper/css/bundle';


const optionAbout = {
  speed: 1200,
  loop: true,
  allowTouchMove: false, 
  autoplay: {
    delay: 3000
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
}

const optionFoods = {
  loop: true,
  slidesPerView: 2,
  spaceBetween: 20,
  speed: 9000,
  initialSlide: 1,
  allowTouchMove: false, 
  autoplay: {
    delay: 0, 
  },
  breakpoints: {
    768: {
      speed: 12000,
      slidesPerView: 2.5,
      spaceBetween: 40,
    }
  }
}

const optionStay = {
  speed: 800,
  slidesPerView: 1.1,
  centeredSlides : true,
  spaceBetween: 10,
  initialSlide: 1,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    768: {
      slidesPerView: 1.5,
      spaceBetween: 20,
    }
  }
}

const optionStayType = {
  speed: 800,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
}

const optionNote = {
  speed: 800,
  slidesPerView: 1.5,
  spaceBetween: 20,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    768: {
      spaceBetween: 40,
      slidesPerView: 2.5,
    },
    992: {
      spaceBetween: 60,
      slidesPerView: 3.5,
    }
  }

}

const optionTime = {
  speed: 1200,
  loop: true,
  autoplay: {
    delay: 6000
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
}

export default function slider() {
  const swiperAbout = new Swiper('.p-about__image', optionAbout);
  const swiperFoods = new Swiper('.p-foods__image', optionFoods);
  const swiperStay = new Swiper('.p-stay__type', optionStay);
  const swiperTime = new Swiper('.p-time__bg', optionTime);
  const swiperCottage = new Swiper('.p-stay__images--cottage', optionStayType);
  const swiperRental = new Swiper('.p-stay__images--rental', optionStayType);
  const swiperCarry = new Swiper('.p-stay__images--carryon', optionStayType);
  const swiperNote = new Swiper('.p-news__note', optionNote);
}