export default function calculate() {

  let cottageRadio = document.getElementsByName('cottagePerson');
  let checkIndexCottage = cottageRadio[0].selectedIndex
  let checkPersonCottage = cottageRadio[0].options[checkIndexCottage].value;
  let checkValueCottage = cottageRadio[0].options[checkIndexCottage].dataset.price;




  let carryonAdultsPeson = document.getElementsByName('carryonPersonAdult');
  let checkValueCarryonAdultsPerson = carryonAdultsPeson[0].value;
  let carryonChildsPeson = document.getElementsByName('carryonPersonChild');
  let checkValueCarryonChildsPeson = carryonChildsPeson[0].value;

  let carryonAmountPrice = Number(checkValueCarryonAdultsPerson) * 3850 + Number(checkValueCarryonChildsPeson) * 2200




  let rentalAdultsPeson = document.getElementsByName('rentalPersonAdult');
  let checkValueRentalAdultsPerson = rentalAdultsPeson[0].value;
  let rentalChildsPeson = document.getElementsByName('rentalPersonChild');
  let checkValueRentalCampChildsPeson = rentalChildsPeson[0].value;

  let rentalAmountPrice = Number(checkValueRentalAdultsPerson) * 7700 + Number(checkValueRentalCampChildsPeson) * 5500



  let breakfastAdultsPeson = document.getElementsByName('breakfastPersonAdult');
  let checkValueBreakfastAdultsPerson = breakfastAdultsPeson[0].value;
  let breakfastChildsPeson = document.getElementsByName('breakfastPersonChild');
  let checkValueBreakfastChildsPeson = breakfastChildsPeson[0].value;

  let breakfastAmountPrice = Number(checkValueBreakfastAdultsPerson) * 1100 + Number(checkValueBreakfastChildsPeson) * 880


  let dinnerAdultsPeson = document.getElementsByName('dinnerPersonAdult');
  let checkValueDinnerAdultsPerson = dinnerAdultsPeson[0].value;
  let dinnerChildsPeson = document.getElementsByName('dinnerPersonChild');
  let checkValueDinnerChildsPeson = dinnerChildsPeson[0].value;

  let dinnerAmountPrice = Number(checkValueDinnerAdultsPerson) * 3850 + Number(checkValueDinnerChildsPeson) * 2750




  let dateRadio = document.getElementsByName('date');
  let datelen = dateRadio.length;

  let checkValueDate = '';
  for (let i = 0; i < datelen; i++){
      if (dateRadio.item(i).checked){
          checkValueDate = dateRadio.item(i).dataset.price;
      }
  }

  let totalAmountPerson = "";
  totalAmountPerson = Number(checkPersonCottage) + Number(checkValueCarryonAdultsPerson) + Number(checkValueCarryonChildsPeson) + Number(checkValueRentalAdultsPerson) + Number(checkValueRentalCampChildsPeson);


  let totalAmountPrice = "";
  totalAmountPrice = Number(checkValueDate) + Number(carryonAmountPrice) + Number(checkValueCottage) + Number(rentalAmountPrice) + Number(breakfastAmountPrice) + Number(dinnerAmountPrice);

  document.getElementById('totalPerson').textContent = totalAmountPerson.toLocaleString();
  document.getElementById('totalPrice').textContent = totalAmountPrice.toLocaleString();

}